.health-bar
{
  position: relative;

  display: inline-block;

  margin: 6px 0;
}

.health-bar:before
{
  content: '';

  z-index: -1;

  position: absolute;
  top: -6px;
  width: 16px;
  height: 28px;

  background-image: url('http://i.imgur.com/s0bMFR7.png');
  background-position: -288px -2px;
  background-repeat: none;
}

.health-bar:after
{
  content: '';

  z-index: -1;

  position: absolute;
  top: -6px;
  right: 0;
  width: 16px;
  height: 28px;

  background-image: url('http://i.imgur.com/s0bMFR7.png');
  background-position: -304px -2px;
  background-repeat: none;
}

.health-bar-glass
{
  display: inline-block;
  width: 200px;
  height: 16px;

  z-index: 10;

  border: 2px solid #000000;
  box-shadow: inset 0 0 0 2px #dbd75d;
}

.health-bar-fluid
{
  width: 60%;
  height: 100%;

  background: #d34549;
  transition: width 0.5s ease;

  box-shadow: inset 0 3px 0 -1px #d3aa9a,
  inset 0 -4px 0 -2px #4d494d,
  inset 4px 0 0 -2px #4d494d,
  inset -4px 0 0 -2px #4d494d;
}


.anim-width
{
  -webkit-animation:  anim50to100 2s infinite;
  animation: anim50to100 2s infinite;
}

/*@-webkit-keyframes anim50to100 {*/
/*  0%   { width: 100%; }*/
/*  50%  { width: 50%; }*/
/*  100% { width: 100%; }*/
/*}*/
/*@keyframes anim50to100 {*/
/*  0%   { width: 100%; }*/
/*  50%  { width: 50%; }*/
/*  100% { width: 100%; }*/
/*}*/




html,
body
{
  padding: 0;
  margin: 0;
}
html
{
  width: 100%;
  height: 100%;

  box-sizing: border-box;
}

*,
*:before,
*:after
{
  box-sizing: inherit;
}


.health-bar-container {
  max-height: 550px;
  overflow: scroll;
  position: absolute;
  z-index: 99;
}